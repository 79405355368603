export default {
	properties: [
		{
			text: 'Imagen',
			key: 'image_url',
			type: 'image',
		},
		{
			text: 'Nombre',
			key: 'name',
			type: 'text',
			value: '',
			is_title: true,
		},
		{
			text: 'Descargar articulos desde el arranque del sistema',
			key: 'download_articles',
			type: 'checkbox',
			value: 0,
			show: true,
		},
		{
			text: 'N° documento',
			key: 'doc_number',
			type: 'text',
			value: '',
			show: true,
		},
		{ 
			text: 'Nombre de la empresa',
			key: 'company_name',
			type: 'text',
			value: '',
			show: true,
		},
		{
			text: 'Telefono',
			key: 'phone',
			type: 'text',
			value: '',
			show: true,
		},
		{
			text: 'Correo electronico',
			key: 'email',
			type: 'text',
			value: '',
			show: true,
		},
		{
			text: 'Iva ya incluido en los precios',
			key: 'iva_included',
			type: 'checkbox',
			description: 'Si se activa, el iva del articulo no impactara en el precio final del mismo',
		},
		{
			text: 'Preguntar la cantidad en VENDER',
			key: 'ask_amount_in_vender',
			type: 'checkbox',
		},
		{
			text: 'Metodo de pago por defecto en VENDER',
			key: 'default_current_acount_payment_method_id',
			type: 'select',
			store: 'current_acount_payment_method',
			description: 'Si se elige, despues de cada venta el valor del metodo de pago se seteara con este valor',
		},
		{
			text: 'Ancho en milimetros de la comandera para imprimir los Tickets de Ventas',
			key: 'sale_ticket_width',
			type: 'number',
		},
		{
			text: 'Valor dolar',
			key: 'dollar',
			type: 'text',
		},
		// {
		// 	text: 'Direccion',
		// 	key: 'address',
		// 	type: 'text',
		// 	value: '',
		// 	show: true,
		// },
	],
	singular_model_name_spanish: 'Usuario',
	plural_model_name_spanish: 'Usuarios',
	create_model_name_spanish: 'Nuevo usuario',
	text_delete: 'el',
}